//@import "~bootstrap-material-design/dist/css/bootstrap-material-design.min.css";
//@import "~bootstrap-material-design/dist/css/ripples.min.css";

@import "variables";
@import "app";
@import "backend";
@import "material-icon";
@import "option-popup";
@import "checkout";
@import "utlility";

@import "import-calculator";

.navigation-container .navbar.navbar-default {
  background-color: transparent;
}