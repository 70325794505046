.configurator {
  padding: 15px 0;

  .configurator-progress {
    margin-top: 0;
    .progress-bar {
      background-color: $primary-color;
    }
    .progress{
      background: $grey-white;
      background-color: $grey-white;
      box-shadow: unset;
      height: 8px;
      margin-bottom: 0;
      border-radius: 0;
    }
  }
  .configurator-form {
    margin-bottom: 30px;
    .configurator-input {
      width: 20%;
      @media (max-width: 1199.98px) {
        width: 33.33%;
      }
      @media (max-width: 991.98px) {
        width: 50%;
      }
      @media (max-width: 767.98px) {
        width: 100%;
      }
      @media (max-width: 575.98px) {

      }
      .form-group {
        margin: 0;
      }
      .is-focused {
        .control-label {
          color: $coal;
        }
      }
      .control-label {
        color: $grey-dark;
        position: absolute;
        top: 8px;
        left: 15px;
        margin-top: 0;
      }
      .material-icons {
        position: absolute;
        right: 10px;
        top: 13px;
        pointer-events: none;
      }
      .form-control {
        background-color: $grey-white !important;
        padding: 20px 15px 2px;
        height: 50px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
      .form-control:disabled {
        color: $grey-dark;
      }
      .form-group.is-focused .form-control {
        background-image: linear-gradient($primary-color, $primary-color), linear-gradient($grey, $grey) !important;
      }
    }
  }

  .configurator-info {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .06), 0 1px 6px 0 rgba(0, 0, 0, .06);
    background-color: $white;
    @media (min-width: 1200px) {
      .configurator-be-advanced-price-label {
        width: 39.8% !important;
      }
      .configurator-be-advanced-price {
        width: 30% !important;
      }
    }
    .configurator-header {
      .configurator-header-price {
        max-width: 180px;
      }
      padding: 15px;
      margin: 0;
      .borc-radio {
        .radio label {
          padding-left: 25px !important;
          //color: $black;
        }
        .radio input[type=radio]:checked ~ .circle {
          border-color: $primary-color !important;
        }
        .radio span {
          left: 0;
        }
        .radio input[type=radio]:checked ~ .check {
          border-color: $primary-color !important;
          background-color: $primary-color !important;
        }
      }
      .img-responsive.photo {
        margin: 15px 0;
        @media (max-width: 991.98px) {
          width: auto;
        }
      }
      //box-shadow: inset  0 0 10px rgba(0,0,0,.06);
      h1 {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 3rem;
      }
      p {
        margin-bottom: 0;
      }
      p.text-small {
        font-size: 1.1rem;
      }
      .configurator-header-exchange {
        p {
          color: #aaa;
        }
      }
      @media (max-width: 767.98px) {
        .configurator-header-info {

        }
        .configurator-header-exchange {
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .configurator-header-price {
          margin-top: 20px;
        }
        .configurator-header-tax {
          margin-top: 20px;
          .info-select-wrapper, select {
            max-width: 100%;
          }
        }
        .pull-right {
          float: left !important;
        }
        .text-right {
          text-align: left !important;
        }
      }
      hr {
        border-bottom: 1px $grey solid;
      }
      .info-select-wrapper {
        max-width: 300px;
        width: 100%;
        margin-top: 0;
        &.is-focused {
          .control-label {
            color: $coal;
          }
        }
        select {
          padding-left: 0;
        }
      }
      .form-label {
        color: $grey-dark;
      }
      .form-group.is-focused .form-control {
        background-image: linear-gradient($primary-color, $primary-color), linear-gradient($grey, $grey) !important;
      }
      .form-control {
        max-width: 300px;
      }
      .btn.btn-raised.btn-warning {
        &.btn-color-grey {
          background-color: $grey;
          &:hover, &:focus {
            background-color: $grey;
          }
        }

        color: #36323a;
        width: 135px;
        border: 1px solid #36323a;
        padding: 8px 6px 6px;
        text-align: left;
        font-weight: 500;

        i {
          padding-left: 0;
        }

        &:hover, &:focus {
          background-color: $primary-color;
        }
      }

      .btn {
        border-radius: 0;
        background-color: $primary-color;
      }
    }

    .configurator-body {
      @media (max-width: 1170px) {
        .nav.nav-tabs > li > a {
          padding: 15px 15px;
        }
      }
      @media (max-width: 970px) {
        .nav.nav-tabs > li > a {
          padding: 15px 10px;
        }
      }

      .btn.btn-raised.btn-warning:disabled {
        background-color: $grey;
        border-color: $grey;

        &:hover, &:focus, &:visited {
          background-color: $grey;
          border-color: $grey;
          box-shadow: none;
        }
      }

      .option-table-header {
        color: #aaa;
      }

      .mobile-label {
        display: none;
        @media (max-width: 991.98px) {
          display: inline;
        }
      }

      .nav-tabs {
        background-color: $primary-color;
        border-bottom: 3px solid rgb(250, 215, 150);

        li {
          margin-bottom: 0;

          a, a:hover {
            font-weight: 600 !important;
          }
        }

        li.active {
          background-color: #fad796;
          border-bottom: none;

          a {
            color: #ef7f01 !important;
          }
        }
      }

      .configurator-tab {
        margin-bottom: 0;

        .panel-default {
          box-shadow: none;
          background-color: unset;
          border: none;

          .panel-heading {
            background-color: transparent;

            .panel-title {
              a {
                color: $black;
                display: block;
              }
            }
          }

          .panel-body {
            border-top: none;
            margin: 15px;
            box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .12), 0 1px 6px 0 rgba(0, 0, 0, .12);
          }
        }

        p {
          margin-bottom: 0;
        }
      }

      .configurator-item-colors-header {
        margin-bottom: 20px;
        @media (max-width: 991.98px) {
          display: none;
        }
      }

      .configurator-item-colors > div:first-child {
        margin-bottom: 10px;
      }

      .configurator-item-colors {
        margin-bottom: 20px;
        @media (max-width: 991.98px) {
          margin-bottom: 50px;
          .text-right {
            text-align: left;
          }
          button {
            max-width: 250px;
          }
        }
        @media (max-width: 575.98px) {
          max-width: 100%;
        }

        button {
          margin-top: 0;
          width: 100%;
          padding: 8px;
        }
      }

      .configurator-item-special-header {
        margin-bottom: 20px;
        @media (max-width: 991.98px) {
          display: none;
        }
      }

      .configurator-item-special {
        margin-bottom: 20px;
        @media (max-width: 991.98px) {
          margin-bottom: 50px;
          .text-right {
            text-align: left;
          }
          button {
            max-width: 250px;
          }
        }
        @media (max-width: 575.98px) {
          max-width: 100%;
        }

        button {
          margin-top: 0;
          width: 100%;
          padding: 8px;
        }
      }

      .configurator-item-series {
        border-bottom: 1px solid $black;
        margin: 0 15px;

        .col-xs-6 {
          padding: 10px 0;
        }
      }

      .configurator-item-series:last-child {
        border-bottom: none;
      }

      .btn {
        border-radius: 0;
      }

      .btn.btn-default, .btn.btn-raised {
        color: $primary-color;
        background-color: unset;
        border-color: $primary-color;
        //&:not(:hover),&:not(:focus) {
        box-shadow: unset;
        //}
        &:hover, &:focus {
          background-color: $primary-color;
          color: $white;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        }
      }

      .btn.btn-raised.btn-warning {
        width: 100%;
        background-color: $primary-color;
        color: $white;

        &:hover {
          background-color: $primary-color;
        }
      }

      .btn.btn-raised.btn-outline-warning {
        width: 100%;
        border: 1px solid $grey;
        color: $grey;
        background-color: transparent;

        &:hover {
          background-color: rgba(255, 165, 0, 0.1);
        }

        box-shadow: none;
      }
    }
  }


  .photo {
    width: 100%;
  }

  .select-icon {
    select ~ i.material-icons {
      position: absolute;
      color: $grey-dark;
      right: 0;
      top: 40px;
      pointer-events: none;
    }
  }
  .btn-icon-addon {
    i.material-icons {
      padding-left: 8px;
    }
  }

  .swatch-list {
    color: $white;
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .swatch-list-item {
      flex: 1;
      height: 100%;
    }
  }
}

select.form-control {
  // hide arrow in IE
  &::-ms-expand {
    display: none;
  }
}

.configurator .configurator-info .configurator-header .info-select-wrapper {
  margin-bottom: 0;
  padding-bottom: 0;
}

.configurator-body .emissions {
  padding: 10px 15px;
  .emission-value {
    border-bottom: 1px solid #000;
    &:last-child {
      border-bottom: none;
    }
  }
  p {
    display: inline-block;
    width: 50%;
    font-size: 15px;
    padding: 10px 0;
    margin: 0;
  }
}

#root hr {
  border-top: 0;
  margin: 0 0 15px 0;
  padding: 0 0 15px 0;
}

@media (min-width: 1200px) {
  .configurator-head-row-image {
    width: 320px !important;
  }
  .configurator-head-row-info {
    width: calc(85% - 320px) !important;
  }
  .configurator-head-row-buttons {
    width: calc(15% - 320px) !important;
  }
}

.configurator .configurator-info .configurator-header .img-responsive.photo {
  width: auto;
}

.pdf-link, .pdf-link:hover, .pdf-link:focus {
  color: #36323a !important;
}

.configurator-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .configurator-loading-icon {
    text-align: center;

    p {
      text-align: center;
      font-size: 16px;
    }

    i {
      text-align: center;
      font-size: 60px;
    }
  }

  //Progress Bar
  .progress {
    position: relative;
    height: 8px;
    display: block;
    width: 100%;
    background-color: #fad796;
    border-radius: 2px;
    background-clip: padding-box;
    margin: 0.5rem 0 1rem 0;
    overflow: hidden;
  }

  .progress .determinate {
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    background-color: $primary-color;
    transition: width .3s linear;
  }

  .progress .indeterminate {
    background-color: $primary-color;
  }

  .progress .indeterminate:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }

  .progress .indeterminate:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
  }

  @-webkit-keyframes indeterminate {
    0% {
      left: -35%;
      right: 100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
  }
  @keyframes indeterminate {
    0% {
      left: -35%;
      right: 100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
  }
  @-webkit-keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
  }
  @keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
  }
}

//.configurator-body {
//  .configurator-item-special {
//    &.status-R {
//      border-left: 5px solid red;
//    }
//    &.status-Y {
//      border-left: 5px solid yellow;
//    }
//    &.status-G {
//      border-left: 5px solid green;
//    }
//  }
//  .configurator-item-colors {
//    &.status-R > div:first-child {
//      border-left: 5px solid red;
//    }
//    &.status-Y > div:first-child {
//      border-left: 5px solid yellow;
//    }
//    &.status-G > div:first-child {
//      border-left: 5px solid green;
//    }
//  }
//}

.status-marking {
  display: none;
}

.configurator-body {
  .status-legend {
    & > .row > div {
      display: flex;
      flex-direction: row;
      padding: 15px 15px 15px;
      //border-bottom: 1px #D2D2D2 solid;
      @media (max-width: 991.98px) {
        padding: 15px 15px 0;
        &:last-child {
          padding-bottom: 30px;
        }
      }
    }

    padding: 0 15px;
    @media (max-width: 991.98px) {
      padding: 0 15px;
    }

    .status-legend-field {
      display: inline-block;

      .status-marking {
        width: 25px;
        height: 25px;
        position: relative !important;
        left: unset !important;

        i {
          font-size: 20px;
        }

        .status-required {
          font-size: 17px;
        }
      }
    }

    .status-legend-label {
      padding: 2px 45px 2px 15px;
      @media (max-width: 991.98px) {
        margin-bottom: 0 !important;
      }
    }
  }

  .configurator-item-special, .configurator-item-colors, .status-legend-field {
    .status-col {
      //display: flex;
      //padding-left: 50px;
    }

    .status-marking {
      display: none;
      position: absolute;
      justify-content: center;
      align-items: center;
      right: 0;
      width: 40px;
      height: 40px;
      //height: 100%;
    }

    .status-check, .status-close, .status-required {
      display: none;
    }

    .status-required {
      font-weight: bolder;
      font-size: 20px;
    }

    &.status-R {
      .status-marking {
        display: flex;
        background-color: $color-excluded;
        color: $white;

        .status-close {
          display: block;
        }
      }
    }

    &.status-Y {
      .status-marking {
        display: flex;
        background-color: $color-required;
        color: $white;

        .status-required {
          display: block;
        }
      }
    }

    &.status-B.status-color-required {
      .status-marking {
        display: flex;
        background-color: $color-required;
        color: $white;

        .status-required {
          display: block;
        }
      }
    }

    &.status-G {
      .status-marking {
        display: flex;
        background-color: $color-selected;
        color: $white;

        .status-check {
          display: block;
        }
      }
    }

    &.status-G.status-included {
      .text-line-through {
        text-decoration: line-through;
      }
      .status-marking {
        display: flex;
        background-color: rgba(76, 175, 80, 0.5);
        color: $white;

        .status-check {
          display: block;
        }
      }
    }
  }
}

.button-check, .button-check:hover, .button-check:focus, .button-check:visited {
  margin-left: auto;
  margin-right: auto;
  display: block !important;
  width: 25px !important;
  height: 25px !important;
  background-color: transparent !important;
  border-width: 3px !important;
  color: transparent;
  padding: 0 !important;

  i {
    position: absolute;
    top: -3px;
    left: -3px;
    font-weight: bolder;
    font-size: 24px !important;
  }

  &.check-checked {
    color: $primary-color !important;
  }

  &.check-unchecked {
    color: transparent !important;
  }

  &.check-disabled {
    color: transparent !important;
    border-color: $grey !important;
  }
}


.emission-value {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.emission-value-image-big {
  max-width: 300px;
  max-height: 300px;
  margin: 10px 0;
}

.emission-value-image-small {
  max-width: 80px;
  max-height: 80px;
  margin: 10px 0;
}

.placeholder-image {
  display: block;
  text-align: center;
  line-height: 150px;
  font-size: 120px;
}

.configurator-scroll-view {
  display: block;
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100vh - 350px);
}

.pdf-print-btn {
  padding: 0 !important;

  span.pdf-link, #pdfButton {
    display: block;
    padding: 8px 6px 6px;

    &.disabled-true {
      pointer-events: none !important;
    }
  }
}

.alert-dismissable .close {
  opacity: .9;
}

.alert h4 {
  margin-bottom: 8px;
}

.alert {
  margin: 8px 16px;
}

.configurator-header-info {
  h1 i.material-icons {
    font-size: 32px;
    font-weight: bold;
    position: absolute;
    padding-left: 10px;
    color: #4CAF50;
  }
}

.configurator-checkout .configurator-header-info {
  i.material-icons, .info-status-text {
    display: none;
  }
  .configurator-header-info-status-complete {
    display: none;
  }
}

.configurator-checkout .configurator-header-price-mode-checkout-info {
  margin-top: 10px;
}

.checkoutFinalOrderButton:disabled {
  color: #BDBDBD;
}

.text-configurator-buttons-info {
  padding-bottom: 15px;
  padding-right: 15px;
  //color: #aaa;
}
.text-line-through {
   text-decoration: line-through !important;
 }

.configurator-text-formatted {
  white-space: pre-line;
  word-break: break-word;
}

.vehicle-request-form-infobox {
  background-color: #90EE90;
  border: 1px solid #2b542c;
  padding: 15px;
  margin: 15px 0 15px;
  h3 {
    font-weight: 500;
  }
  a {
    cursor: pointer;
  }
}

.popup-required-options-content {
  .configurator-scroll-view {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 15px);
  }
  .panel-default {
    box-shadow: none;
    background-color: unset;
    border: none;
    .panel-heading {
      background-color: transparent;
      .panel-title {
        position: relative;
        a {
          color: $black;
          display: block;
          i.material-icons.pull-right {
            top: 0;
          }
        }
      }
    }
    .panel-body {
      border-top: none;
      margin: 15px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .12), 0 1px 6px 0 rgba(0, 0, 0, .12);
    }
  }
}

.configurator-header-info.col-sm-8 {
  @media (min-width: 768px) {
    width: 72%;
  }
}
.configurator-header-exchange.col-sm-4 {
  @media (min-width: 768px) {
    width: 28%;
  }
}

.configurator-header-info {
  .configurator-header-info-name {
    margin-right: 5px;
  }
  .configurator-header-info-status-complete {
    color: #4CAF50;
    font-size: 12px;
    line-height: 30px;
    position: relative;
    display: inline-block;
    padding-left: 32px;
    i.material-icons {
      font-size: 30px;
      padding-left: 0;
      position: absolute;
      top: -1px;
      left: 0;
    }
    .info-status-text {
      font-size: 12px;
      vertical-align: 7px;
    }
  }
  .configurator-header-info-status-not-complete {
    color: #F1B91B;
    font-size: 12px;
    line-height: 30px;
    position: relative;
    display: inline-block;
    padding-left: 12px;
    span.status-icon-warning {
      font-size: 30px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .info-status-text {
      font-size: 12px;
      vertical-align: 7px;
    }
  }
}

.configurator-header-price.configurator-header-price-value {
  text-align: right;
}