@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-1turn);
  }
}

.material-icons{
  &.spin {
    animation: spinner 2s linear infinite;
  }
}