.d-col,
.d-row,
.d-flex {
  display: flex;
}

.d-col {
  flex-direction: column;
}
.d-row {
  flex-direction: row;
}

.align-items-center {
  align-items: center;
}

.justify-space-between {
  justify-content: space-between;
}

.m-0 {
  margin: 0 !important;
}

.money-pit {
  width: 70px;
}