
  #vehicles-backend-module {
    #configurator-backend, .configurator-backend {
      background-color: $grey-white;
      padding: 30px;

      .configurator-backend-header {
        background-color: $white;
        padding: 15px;
        color: #aaa;
        font-weight: 400;
      }

      .configurator-backend-item {
        background-color: $white;
        color: $coal;
        font-weight: 400;
        padding: 15px;
        margin-top: 5px;

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          opacity: 1;
        }

        .input-number {
          width: 90px;
          text-align: center;
        }

        .btn {
          margin-top: 0;
          background-color: $green-color;
          color: $white;
          border: none !important;
          margin-bottom: 5px;

          &:focus, &:hover {
            background-color: $green-color !important;
            color: $white;
          }
        }

        .checkbox {
          margin-top: 0 !important;
        }

        .checkbox input[type=checkbox]:checked + .checkbox-material .check, label.checkbox-inline input[type=checkbox]:checked + .checkbox-material .check {
          color: $blue-color;
          border-color: $blue-color;

          &:before {
            color: $blue-color;
          }
        }
      }

    }

    .configurator-header-price-factor {
      color: $red-color;
    }

    .configurator-header-price-borc {
      max-width: 330px;
      float: right;
      .borc-radio {
        width: auto;
        float: left;

        .radio > label {
          padding-left: 35px;
        }
      }

      //.radio input[type=radio], label.radio-inline input[type=radio] {
      //  opacity: 1;
      //  height: 20px;
      //  width: 20px;
      //}
    }

    .price-with-factor {
      color: $red-color;
    }

    .configurator-backend-item-emissions {
      hr {
        border-bottom: 1px #D2D2D2 solid;
        padding: 0 !important;
        margin: 0 15px 30px !important;
      }

      background-color: $white;
      padding: 15px;

      .input-number, .input-text {
        width: 80px;
        margin-bottom: 15px;
        margin-right: 15px;
      }

      label {
        font-weight: 400;
        color: $coal;
        font-size: 15px;
        padding-top: 2px;
      }
    }

    .emission-field {
      margin-bottom: 5px;
    }

    .emission-field > input {
      padding: 0 5px;
      width: 80px;
    }

    .emission-input-label {
      margin-right: 15px;
      width: 160px;
    }

    .emission-input-select {
      width: auto;
      max-width: 160px;
      display: inline-block !important;
    }

    #configurator-backend > .btn.btn-default {
      margin: 0 0 15px -15px;
    }

    .backend-settings {
      .backend-settings-button {
        margin-left: auto;
        margin-right: 0;
        display: block;
      }

      .backend-settings-wrapper {
        background-color: #fafafa;
        padding: 30px 15px;
      }

      .backend-settings-body {
        background-color: #fff;
        padding: 15px;
        margin: 0;
      }

      .form-group .form-control {
        background-image: linear-gradient($primary-color, $primary-color), linear-gradient($grey, $grey) !important;
      }

      .form-group.is-focused label.control-label {
        color: #000;
      }

      label {
        color: #000;
        font-size: 16px;
        line-height: 1.2;
      }

      .backend-settings-save {
        display: block;
        background-color: #eee !important;
      }
    }

    .backend-info-view > div {
      margin-top: 30px;
    }

    & > .nav-tabs {
      background-color: #484848;
      border-bottom: 3px solid #777;
      & > li {
        a {
          padding: 15px 20px !important;
        }
        a, a:hover, a:focus, a:visited {
          font-weight: 500;
        }
        &.active {
          background-color: #777;
        }
      }
    }
  }

  .configurator-backend {
    background-color: $grey-white;
    padding: 30px;

    .configurator-backend-header {
      background-color: $white;
      padding: 15px;
      color: #aaa;
      font-weight: 400;
    }

    .configurator-backend-item {
      background-color: $white;
      color: $coal;
      font-weight: 400;
      padding: 15px;
      margin-top: 5px;

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        opacity: 1;
      }

      .input-number {
        width: 90px;
        text-align: center;
      }

      .btn {
        margin-top: 0;
        background-color: $green-color;
        color: $white;
        border: none !important;
        margin-bottom: 5px;

        &:focus, &:hover {
          background-color: $green-color !important;
          color: $white;
        }
      }

      .checkbox {
        margin-top: 0 !important;
      }

      .checkbox input[type=checkbox]:checked + .checkbox-material .check, label.checkbox-inline input[type=checkbox]:checked + .checkbox-material .check {
        color: $blue-color;
        border-color: $blue-color;

        &:before {
          color: $blue-color;
        }
      }
    }

  }

  .configurator-header-price-factor {
    color: $red-color;
  }

  .configurator-header-price-borc {
    .borc-radio {
      width: 300px;
      float: right;

      .radio > label {
        padding-left: 35px;
      }
    }

    //.radio input[type=radio], label.radio-inline input[type=radio] {
    //  opacity: 1;
    //  height: 20px;
    //  width: 20px;
    //}
  }

  .price-with-factor {
    color: $red-color;
  }

  .configurator-backend-item-emissions {
    hr {
      border-bottom: 1px #D2D2D2 solid;
      padding: 0 !important;
      margin: 0 15px 30px !important;
    }

    background-color: $white;
    padding: 15px;

    .input-number, .input-text {
      width: 80px;
      margin-bottom: 15px;
      margin-right: 15px;
    }

    label {
      font-weight: 400;
      color: $coal;
      font-size: 15px;
      padding-top: 2px;
    }
  }

  .emission-field {
    margin-bottom: 5px;
  }

  .emission-field > input {
    padding: 0 5px;
    width: 80px;
  }

  .emission-input-label {
    margin-right: 15px;
    width: 160px;
  }

  .emission-input-select {
    width: auto;
    max-width: 160px;
    display: inline-block !important;
  }

  .configurator-backend > .btn.btn-default {
    margin: 0 0 15px -15px;
  }

  .btn-update-vehicle-data-all {
    margin: 0 0 15px -15px !important;
  }

  .btn-update-vehicle-data-single {
    margin: 0 0 15px 15px !important;
  }


  .backend-overrides {

    background-color: #fafafa;
    padding: 30px 15px;

    .backend-data-table-wrapper {
      background-color: #fff;
      padding: 15px;
    }

    .btn.btn-raised.btn-default {
      margin-bottom: 15px;
    }

    table {

      margin-bottom: 5px;

      thead {
        tr:first-child {
          th {
            text-align: center;
          }
        }
        th:nth-last-child(2) {
          min-width: 300px;
        }
      }

      th {
        min-width: 100px;
        padding: 5px;
        &>div {
          margin: -5px;
        }
      }

      td, th {
        min-width: 20px;
        input[type="checkbox"] {
          margin-top: 8px;
        }
        input {
          border: none;
          width: 100%;
          padding: 5px;
        }
        select {
          border: none;
          width: 100%;
          padding: 5px;
        }
      }
    }

  }