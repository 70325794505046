.checkout-stepper-steps {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .checkout-stepper-step {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 10px 0;

    &.active {
      background-color: #fafafa;
    }

    &.locked {
      color: #ddd;
      &:hover, &:focus {
        background-color: transparent !important;
        cursor: auto;
      }
    }

    &:hover, &:focus {
      background-color: #f0f0f0 !important;
      cursor: pointer;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
}

.checkout-stepper {
  .configurator-progress {
    margin-top: 0;
  }
}

.configurator-checkout {
  .panel-heading .panel-title h2 {
    position: relative;
    i.material-icons {
      top: 0;
    }
  }

  .material-icons.placeholder-image {
      position: relative;
      right: unset;
      bottom: 0px;
  }
  .checkout-button-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    button.btn {
      max-width: 250px;
    }
  }
  .checkout-step-title {
    margin-bottom: 30px;
  }
  .checkout-detail-info {
    .row {
      margin-bottom: 15px;
    }
    h5.detail-color-category {
      padding: 0 0 10px 0;
    }
  }
  .mobile-label {
    display: none;
  }
  .configurator-item-special .status-col {
    width: 50% !important;
  }
  .popup-col-thin {
    width: 16.6666666667% !important;
  }
  .col-detail-color {
    width: 33.3333333333% !important;
  }
  .col-detail-button {
    display: none;
  }

  .configurator-header {
    padding: 15px;
    margin: 0;
    .borc-radio {
      .radio label {
        padding-left: 25px !important;
        //color: $black;
      }
      .radio input[type=radio]:checked ~ .circle {
        border-color: $primary-color !important;
      }
      .radio span {
        left: 0;
      }
      .radio input[type=radio]:checked ~ .check {
        border-color: $primary-color !important;
        background-color: $primary-color !important;
      }
    }
    .img-responsive.photo {
      margin: 15px 0;
      @media (max-width: 991.98px) {
        width: auto;
      }
    }
    //box-shadow: inset  0 0 10px rgba(0,0,0,.06);
    h1 {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 3rem;
    }
    p {
      margin-bottom: 0;
    }
    p.text-small {
      font-size: 1.1rem;
    }
    .configurator-header-exchange {
      p {
        color: #aaa;
      }
    }
    @media (max-width: 767.98px) {
      .configurator-header-info {

      }
      .configurator-header-exchange {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .configurator-header-price {
        margin-top: 20px;
      }
      .configurator-header-tax {
        margin-top: 20px;
        .info-select-wrapper, select {
          max-width: 100%;
        }
      }
      .pull-right {
        float: left !important;
      }
      .text-right {
        text-align: left !important;
      }
    }
    hr {
      border-bottom: 1px $grey solid;
    }
    .info-select-wrapper {
      max-width: 300px;
      width: 100%;
      margin-top: 0;
      &.is-focused {
        .control-label {
          color: $coal;
        }
      }
      select {
        padding-left: 0;
      }
    }
    .form-label {
      color: $grey-dark;
    }
    .form-group.is-focused .form-control {
      background-image: linear-gradient($primary-color, $primary-color), linear-gradient($grey, $grey) !important;
    }
    .form-control {
      max-width: 300px;
    }
    a.btn.btn-raised.btn-warning {
      &.btn-color-grey {
        background-color: $grey;
        &:hover, &:focus {
          background-color: $grey;
        }
      }
      color: #36323a;
      width: 135px;
      border: 1px solid #36323a;
      padding: 8px 6px 6px;
      text-align: left;
      font-weight: 500;
      i {
        padding-left: 0;
      }
      &:hover, &:focus {
        background-color: $primary-color;
      }
    }
    .btn {
      //border-radius: 0;
      //background-color: $primary-color;
    }
  }
}
.checkout-detail-info, .checkout-step-final {
  .emissions .emission-value {
    border-bottom: 1px solid #000;
    &:last-child {
      border-bottom: none;
    }
  }
  .emissions p {
    display: inline-block;
    width: 50%;
    font-size: 15px;
    padding: 10px 0;
    margin: 0;
  }
}

.checkout-step-final .emissions {
  border: 1px solid #000;
  .emission-value {
    p {
      padding: 5px 10px;
    }
  }
}

.checkout-step-payment {
  & > div {
    max-width: 600px;
    margin: 0 auto;
  }
  .checkout-payment-card {
    //border: 1px $grey solid;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-bottom: 30px;
    .checkout-payment-input {
      input {
        margin: 0 15px 0 0;
      }
    }
    .checkout-payment-logo {
      margin-bottom: 30px;
      img {
        max-width: 150px;
        width: 150px;
      }
    }
    .checkout-payment-description {
      margin-top: 30px;
      color: #36323a;
      font-weight: normal;
    }
  }
}

.checkout-step-summary {
  .checkout-summary-row {
    margin-bottom: 10px;
    input {
      width: 100%;
      max-width: 300px;
      &.summary-input-checkbox {
        width: unset;
      }
    }
    select {
      width: 100%;
      max-width: 300px;
      height: 27px;
    }
  }
}
.checkout-step-final {
  .configurator-header-price p {
    margin-bottom: 0;
  }
  .billing-address-info p {
    margin-bottom: 0;
  }
  .checkout-table {
    td, th {
      padding: 5px 10px;
    }
    .checkout-table-option {

    }
    .checkout-table-price {
      text-align: right;
    }
  }
  .checkout-edit-link {
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
  }
  .checkout-step-title {
    margin-top: 45px;
  }
  .checkout-payment-logo {
    margin-bottom: 30px;
    img {
      max-width: 150px;
      width: 150px;
    }
  }
  .main.payment-buttons {
    padding: 30px 0;
  }
  .finish-payment-button {
    padding-top: 15px;
  }
}

.checkout-success {
  .checkout-document-download-link {
    color: #ef7f01;
    background: transparent;
    text-decoration: none;
    &:hover {
      color: #36323a;
    }
  }
}

.checkout-summary-select {
  .checkout-summary-warning {
    color: #F44336;
    margin: 0;
  }
}