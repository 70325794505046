
  .import-calculator {
    max-width: 600px;
    margin: 60px auto;
    padding: 30px;
    border: 1px solid #d2d2d2;

    hr {
      padding: 0 !important;
      margin: 50px 0 !important;
      border-color: #d2d2d2;
    }

    h1 {
      @media (max-width: 767px) {

      }
    }

    h1, label {
      //color: #0b2e13;
      //font-weight: normal;
      display: inline-block;
      float: left;
      @media (max-width: 767px) {
        float: none;
      }
    }

    input {
      display: inline-block;
      float: right;
      width: 200px;
      height: 28px;
      padding: 5px;
      position: relative;
      @media (max-width: 767px) {
        float: none;
      }
    }

    .radioLabel {
      float: none;
    }

    input[type="radio"] {
      float: left;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-top: 0;
    }

    .row {
      margin: 15px 0;
      @media (max-width: 767px) {

      }
    }

    .field-logistic {
      border: none;
    }

    .field-total {
      border: none;
      height: 28px;
      font-size: 25px;
      font-weight: bold;
    }

    .iconPlus {
      font-weight: bold;
      position: absolute;
      font-size: 24px;
      line-height: 28px;
      padding-left: 12px;
      color: #ef7f01;
      z-index: 1;
      @media (max-width: 767px) {
        left: -20px;
      }
    }

    .colored {
      color: #ef7f01;
    }
  }
