.optionPopup, .savedVehiclesPopup, .configurator-checkout, .vehicle-request-form {
  .configurator-item-special, .configurator-item-color {
    @media (min-width: 992px) {
      .popup-col-thin {
        width: 14.6666666667%;
      }
      .popup-col-thick {
        width: 22.6666666667%;
      }
    }
    @media (max-width: 991px) {
      margin-top: 30px;
      margin-bottom: 30px;
      .popup-col-thin {
        width: 33.33333333%;
        p {
          text-align: left !important;
        }
      }
      .popup-col-thick {
        width: 100%;
      }
    }
    @media (max-width: 767px) {
      .popup-col-thin {
        width: 100%;
        p {
          text-align: left !important;
          .mobile-label {
            width: 120px;
            text-align: left !important;
            display: inline-block !important;
          }
        }
      }
    }
  }
  padding-left: 0 !important;
  .status-R {
    .btn.btn-raised.btn-warning:disabled {
      background-color: transparent !important;
      border-color: $grey !important;
      &:hover, &:focus, &:visited {
        background-color: transparent !important;
        border-color: $grey !important;
        box-shadow: none !important;
      }
    }
    .option-table-header {
      color: $grey;
    }
  }
  .mobile-label {
    display: block;
  }
  .control-label {
    color: #BDBDBD;
  }
  .radio label {
    color: $black;
  }
  .radio input[type=radio]:checked~.circle {
    border-color: $primary-color !important;
  }
  .radio span {
    left: 0;
  }
  .radio input[type=radio]:checked~.check {
    border-color: $primary-color !important;
    background-color: transparent !important;
  }
  .material-icons {
    position: absolute;
    right: 0;
    bottom: 20px;
  }
  .modal-content {
    border-radius: 0;
  }
  .btn {
    border-radius: 0;
  }
  .btn.btn-default, .btn.btn-raised {
    color: $primary-color;
    background-color: unset;
    border-color: $primary-color;
    //&:not(:hover),&:not(:focus) {
    box-shadow: unset;
    //}
    &.button-check, &:disabled {
      background-color: transparent !important;
    }
    &:hover, &:focus {
      &.button-check, &:disabled {
        background-color: transparent !important;
        color: $primary-color;
      }
      &:disabled {
        box-shadow: none;
      }
      background-color: $primary-color !important;
      border-color: $primary-color !important;
      color: $white;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    }
  }
  .btn.btn-raised {
    width: 100%;
  }
  .btn.btn-raised.btn-warning {
    width: 100%;
    background-color: transparent !important;
    color: $white;
    &:hover {
      background-color: transparent !important;
    }
  }
  .modal-footer {
    padding: 0 24px 24px 24px;
    @media (max-width: 600px) {
      display: flex;
    }
    .btn.btn-raised {
      width: 180px;
      @media (max-width: 600px) {
        width: 50%;
      }
    }
  }
}

.savedVehiclesPopup .modal-dialog .modal-body {
  .radio input[type=radio]:checked~.check {
    border-color: $primary-color !important;
    background-color: $primary-color !important;
  }
}

.savedVehicles {
  button.btn {
    z-index: 2;
    width: auto;
    max-width: 100%;
    margin-left: auto;
    overflow: hidden;
    min-width: 320px;
    box-sizing: border-box;
    @media (max-width: 500px) {
      min-width: unset;
    }
    span {
      display:block;
      word-wrap:break-word;
    }
  }
  margin: -15px 0 30px auto;
  width: 100%;
  position: relative;
  @media (max-width: 767px) {
    max-width: unset;
  }
  .text-left {
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px #ddd;
    position: absolute;
    z-index: 1;
    width: auto;
    right: 0;
    @media (max-width: 767px) {
      max-width: unset;
    }
    .list-group {
      border: 1px solid #bdbdbd;
      background-color: #fff;
      margin-bottom: 0;
    }
    .list-group-item {
      padding: 5px 30px 5px 15px;
      i.material-icons {
        position: absolute;
        right: 7px;
        top: 7px;
        font-size: 17px;
      }
    }
  }
}

.vehicle-request-form {
  box-shadow: 0 1px 6px 0 rgba(0,0,0,.06), 0 1px 6px 0 rgba(0,0,0,.06);
  padding: 15px !important;
  .panel-heading {
    //padding: 0;
    .panel-title {
      a {
        margin: -10px -15px;
        padding: 10px 15px;
        display: block;
        position: relative;
        .material-icons {
          bottom: unset;
          right: 25px;
        }
      }
    }
  }
  .emission-value {
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
    .text-left {
      max-width: 50%;
      margin-bottom: 10px;
    }
    .text-right {
      max-width: 50%;
      margin-bottom: 10px;
    }
    .emission-value-image-big {
      max-width: 50%;
    }
  }
  .vehicle-request-form-title {

  }
  .vehicle-request-form-buttons {
    button {
      width: 184px !important;
      margin-left: 8px;
      margin-right: 8px;
      margin-top: 24px;
    }
  }
  .form-group.is-focused label.control-label {
    color: $primary-color !important;
  }
  .form-control, .form-group .form-control {
    background-image: linear-gradient($primary-color,$primary-color),linear-gradient(#D2D2D2,#D2D2D2) !important;
  }
}

.popup-category-title {
  font-size: 16px;
}