$white: #fff;
$black: #000;

$coal: #36323a;

$grey-darker: #aaa;
$grey-dark: #bbb;
$grey: #D2D2D2;
$grey-light: #eee;
$grey-white: #fafafa;

$primary-color: #ef7f01;

$green-color: #4CAF50;
$red-color: #F44336;
$blue-color: #5DADE2;

$color-selected: $green-color;
$color-excluded: $red-color;
$color-required: #F1B91B;

$color-selected-dark: darkgreen;